<template>
<!-- // 判断如果是左边的学院新闻就设置li行高 -->
  <div class="school_box" :id="type_ == 1?'':'school_box2'">
    <header @click="home_(data.id)">{{data.title}} <a>MORE></a></header>
    <div class="card" v-if="type_ == 1" @click="home_item_(props.data.item[0])">
      <p>
        <span>{{props.data.item[0].created_at.slice(8,10)}}</span>
        <a href=":;">{{props.data.item[0].created_at.slice(0,7)}}</a>
      </p>
      <section>{{props.data.item[0].title}}</section>
    </div>
    <ul>
      <!-- 判断是重要通知就从第一个开始遍历 -->
      <li v-for="(element,i) in props.type_ == 1?props.data.item.filter((x,y) => y !== 0):props.data.item" :key="i" @click="home_item_(element)">
        <a><img src="@/assets/icon2.png" alt=""></a><em>{{element.title}}</em><span>{{element.created_at.slice(0,10)}}</span></li>
    </ul>
  </div>
</template>
<script setup>
import {ref,reactive,defineEmits,defineProps,computed} from 'vue'
const props = defineProps({
  title:String,
  type_:String,
  data:Object
})
const emits = defineEmits(['home_header','home_item'])
const home_ = (idd) => {
  emits('home_header',idd)
}
const home_item_ = (item) => {
  emits('home_item',item)
}

</script>

<style lang="less" scoped>

@maxw:128rem;
.school_box{
  margin-top: 6/@maxw;
  width: 770/@maxw;
  max-height:510/@maxw;
  transition: ease .5s;
  padding-bottom: 5/@maxw;
  overflow: hidden;
  font-family: Source Han Sans CN-Medium;
  cursor: pointer;
  >header{
         -webkit-font-smoothing: subpixel-antialiased;
          width: 100%;
          height: 60/@maxw;
          border-bottom: 4/@maxw solid #aaa;
          font-size: 39/@maxw;
          // font-family: Source Han Sans CN-Medium;
          // font-weight: bolder;
          box-sizing: border-box;
          font-family: fantBold;
          //   font-weight: 300;
          color: #174994;
          display: flex;
          justify-content: space-between;
          // padding: 0 0 18/@maxw 0;
          padding: 0 0 70/@maxw 0;
          >a{
            margin-top: 10/@maxw;
            font-size: 18/@maxw ;
            font-family: Source Han Sans CN-Medium;
            color: #B19A86;
            line-height: 60/@maxw;
            font-weight: bolder;
          }
  }
  >ul{
    margin: 28/@maxw 0 0 0;
    padding: 0;
    width: 100%;
    height: 100%;
    >li{
    margin: 20/@maxw 0;
    width: 100%;
    height: 24/@maxw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: ease .3s;
    >em{
    font-size: 24/@maxw;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 500;
    color: #292929;
    flex: 1;
    font-style: normal;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    // white-space: nowrap;
    padding-right: 8/@maxw;
    }
    >a{
      color: #517DBE;
      margin-right: 10/@maxw;
      margin-left: 5/@maxw;
      font-size: 20/@maxw;
      img{
        width: 7/@maxw;
        height: 12/@maxw;
      }
    }
    >span{
      font-size: 20/@maxw;
      padding-right: 5/@maxw;
      color: #5F5F5F;
    }
  }
  >li:hover{
        background-color: rgb(242, 240, 240);
      }
  }

  .card{
    position: relative;
    margin-top: 33/@maxw;
    width: 767/@maxw;
    height: 108/@maxw;
    background: url("../assets/3109163a4904e0867561b8921adf9c4.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: ease .3s;
    p{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 0 0 -6/@maxw;
      padding: 0;
      width: 130/@maxw;
      height: 100%;
      color:#d7b497;
      >span{
        font-size: 55/@maxw;
        font-family: Source Han Sans CN-Medium;
        font-weight: bold;
        color: #D1BAA6;
      }
      >a{
        margin-top: -10/@maxw;
        margin-bottom: 10/@maxw;
        font-size: 19/@maxw;
        font-family: Source Han Sans CN-Medium;
        font-weight: bold;
        color: #D1BAA6;
      }
    }
    >section{
      box-sizing: border-box;
      padding: 20/@maxw;
      // width: 546;
      padding-left:35/@maxw;
      padding-right:30/@maxw;
      flex: 1;
      height: 100%;
      font-size: 26/@maxw;
      font-family: Source Han Sans CN-Medium;
      font-weight: 800;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      overflow: hidden;
    }
    >section::before{
          position: absolute;
          top: 28%;
          left: 125/@maxw;
          content: '';
          width: 2/@maxw;
          height: 43/@maxw;
          background-color: #fff;
    }
  }
  .card:hover{
    box-shadow: 0 0 10px #174994;
  }
}
// 判断如果是左边的学院新闻就设置li行高
 #school_box2{
  >ul{
    // margin-top: 38/@maxw;
    margin-top: 39/@maxw;
  }
}
</style>