<template>
  <pchome :datas="data" :jzbgs="data.jzbgs"></pchome>
</template>

<script setup>
import pchome from '@/views/laboratory/laboratoryHome/pchome.vue'
import * as http from '@/api/API.js'
import { reactive,ref } from 'vue'
const data = reactive({
  bannerList:[],
  ind: {},
  jzbgs:[]
})
// 获取轮播图
const bannerList = async () => {
  const {data:res} = await http.getHttp("/api/banner",{is_en:2})
  if(res.code == 200){
    getHome()
    data.bannerList = res.data
  }
}
bannerList()
const getHome = () => {
  http.get_home_list({is_en:2}).then(res => {
    if (res.data.code == 200) {
        data.jzbgs = res.data.data.find(x => x.id == 387)
        const obj = {
          zhxw:res.data.data.find(x => x.id == 385),
          tzgg:res.data.data.find(x => x.id == 386),
          jzbg:res.data.data.find(x => x.id == 387),
          kydt:res.data.data.find(x => x.id == 388)
      }
        data.ind = obj
    }
  })
}
</script>

<style lang="less" scoped>

</style>
