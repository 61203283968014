<template>
  <div class="homebox">
    <Header></Header>
    <!-- 轮播图 区域-->
    <div class="swiper_box">
      <!-- 分页器 -->
      <ul ref="pagination_banner">
        <li v-for="(item, i) in data.imgarrList" :key="i"></li>
        <!-- 显示轮播图的数量 -->
        <div class="banner_num">
          <span>0{{ data.banner_num }}</span>/<span>0{{ data.imgarrList.length }}</span>
        </div>
      </ul>
      <swiper class="swiper" :modules="modules" :slides-per-view="1" :speed="1500" :loop="true" :autoplay="{ delay: 4000 }"
        navigation @slideChange="onSlideChange" @swiper="onSwiper">
        <swiper-slide v-for="(item, i) in data.imgarrList" :key="i" class="slideImg">
          <!-- 自定义编辑banner标题 -->
          <div id="button_" @click="swiperButton(item)" v-if="item.is_more == 1">
            <span>发现更多</span>
            <img src="@/assets/fxwm.png" />
          </div>
          <img :src="item.url" style="width:100%;height:100%;">
        </swiper-slide>
      </swiper>
    </div>
    <div class="nav">
      <div class="navOnetop">
        <div class="nav_top">
          <header @click="homeArticle(data.indarr.zhxw)">综合新闻 <a>MORE></a></header>
          <nav @click="homeArticle(data.indarr.zhxw?.item[1])">
            <span>{{ data.indarr.zhxw?.item[1].created_at.slice(0, 11) }}</span>
            <h1>{{ data.indarr.zhxw?.item[1].title_en }}</h1>
            <p>{{ data.indarr.zhxw?.item[1].description_en }}</p>
          </nav>
          <footer @click="homeArticle(data.indarr.zhxw?.item[2])">
            <span>{{ data.indarr.zhxw?.item[2].created_at.slice(0, 11) }}</span>
            <h1>{{ data.indarr.zhxw?.item[2].title_en }}</h1>
          </footer>
        </div>
        <div class="nav_middle" @click="homeArticle(data.indarr.zhxw?.item[0])">
          <div class="img1"><img :src="baseUrl + data.indarr.zhxw?.item[0]?.thumb" alt=""></div>
          <div class="img2">
            <section></section>
            <div class="nav_title">
              <h1>
                <span>{{ data.indarr.zhxw?.item[0].title_en.slice(0, 21) }}</span>
                <p>{{ data.indarr.zhxw?.item[0].title_en.slice(21) }}</p>
              </h1>
              <div class="right_date">
                <span>{{ data.indarr.zhxw?.item[0].created_at.slice(0, 11) }}</span>
                <em></em>
              </div>
            </div>
          </div>
        </div>
        <div class="nav_bottom">
          <header @click="homeArticle(data.indarr.jzbg)">讲座报告 <a>MORE></a></header>
          <div class="navs">
            <ol ref="Pagerdot">
              <li v-for="(item, i) in data.indarr.jzbg?.item" :key="i" @click="carouselClick(i)"></li>
            </ol>
            <el-carousel class="carousel_box" indicator-position="none" @change="carouselChange" ref="carousel" loop>
              <el-carousel-item v-for="(elm, i) in props.jzbgs?.item" :key="elm.id" @click="_" class="carousel_item">
                <div class="text_p" v-for="(item2, i2) in elm" :key="item2.id" @click.stop="homeArticle(item2)">
                  <p>{{ item2.title_en }}</p>
                  <div class="xw_desc">{{ item2.description_en }}...</div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div class="navTwotop">
        <labhomeItem title="科研动态" :datas="data.indarr.kydt ?? {}" @home_header="homeArticle" @home_item="homeArticle" />
        <labhomeItem title="通知公告" type_="1" :datas="data.indarr.tzgg ?? {}" @home_header="homeArticle"
          @home_item="homeArticle" />
      </div>
      <pcfooter />
    </div>
  </div>
</template>

<script setup>
import Header from '@/components/laboratoryCom/labheader.vue'
import { ref, reactive, onMounted, watch, onUnmounted, defineProps, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";
import "swiper/modules/autoplay/autoplay.min.css";
import miiter from '@/utils/miitbus.js'
import Schooljournalism from '../../../components/school_Journalism.vue'
import pcfooter from '@/components/laboratoryCom/labfooter.vue'
import labhomeItem from '@/components/laboratoryCom/labhomeItems.vue'
// swiper配置
let modules = reactive([Navigation, Pagination, Scrollbar, A11y, Autoplay,])
const router = useRouter()
const props = defineProps({
  datas: Object,
  jzbgs: Object
})
const data = reactive({
  imgarrList: [],
  banner_num: null,
  Swiper: {},
  indarr: {}
})
const baseUrl = ref(process.env.VUE_APP_BASE_URL + '/')
// 获取swiper实例
let onSwiper = (eee) => { data.Swiper = eee; }

// 处理父级传过来的数据
const propsSet = () => {
  data.imgarrList = props.datas.bannerList
  data.indarr = props.datas.ind
  // nextTick(() => carouselChange(0))
}
// 点击轮播按钮
const swiperButton = (e) => {
  router.push({
    name: 'Moearticlelist',
    params: {
      id: 361,
      tid: 360,
      level: 2,
      isen: 2
    }
  })
  // if(e.link_type == 1){
  //     router.push({
  //     name: 'Enarticlexq',
  //     params:{
  //       articleid:e.link,
  //       id:e.id,
  //       tid:303,
  //       level:1
  //     }
  //   })
  //   return
  // }
  // window.open(e.link)
}
// 监听父组件传过来的值变化
watch(() => props.datas, () => {
  propsSet()
}, { deep: true })

onMounted(() => {
  data.Swiper.slideTo(1)
  nextTick(() => setTimeout(() => onSlideChange({ realIndex: 0 }), 1000))
  domscrool()
})
const pagination_banner = ref(null)
// 首页轮播
const onSlideChange = (e) => {
  data.banner_num = e.realIndex + 1
  const li = pagination_banner.value.children
  if (li.length <= 1) return
  excludes(li, (e) => { li[e].style['height'] = '1px'; li[e].style['margin-top'] = '0' })
  li[e.realIndex].style['height'] = '5px'
  li[e.realIndex].style['margin-top'] = '-4px'
}
const Pagerdot = ref(null)
// 讲座报告轮播
const carouselChange = (e) => {
  const uls = Pagerdot.value.children
  if (!uls) return
  excludes(uls, (i) => uls[i].style.background = 'none')
  uls[e].style.background = '#B19A86'
}
const carousel = ref(null)
// 讲座报告分页点击
const carouselClick = (e) => carousel.value.setActiveItem(e)
// 首页dom滚动事件
const domscrool = () => {
  const domScrolltop = document.documentElement
  document.addEventListener('scroll', () => {
    if (domScrolltop.scrollTop >= 800) {
      miiter.emit('laboratoryScroll')
    } else {
      miiter.emit('unlaboratoryScroll')
    }
  })
}
// 排他
const excludes = (arr, callback) => {
  for (let index = 0; index < arr.length; index++) {
    callback(index)
  }
}
// 首页模块点击，跳转对应路由
const homeArticle = (item, e) => {
  router.push({
    name: item.created_at ? 'Moearticlexq' : item.vue_url,
    params: {
      articleid: item.created_at && item.id,
      id: item.created_at ? item.type_id : item.id,
      tid: item.created_at ? item.type_id : item.tid,
      level: item.created_at ? 1 : item.level,
      isen: 2
    }
  })
}
// 卸载
onUnmounted(() => {
  miiter.off('laboratoryScroll')
  miiter.off('unlaboratoryScroll')
})

</script>

<style lang="less" scoped>
@import url('../../../style/laboratoryhome.less');
</style>      